export const bigCardsContent = [
  {
    title: "Answer Your\n Health Questionnaire",
    text: "Start by filling out your precision health questionnaire which collects information on your health, habits, and training",
    link: "questionnaire",
    buttonText: "Start Here",
    isSupplementTile: false,
  },
  {
    title: "How To Read",
    text: "Before diving into your report, watch a short demo on how to get the most out of your report, the science behind it, and check off the box to confirm you recognise the implications of your data",
    link: "how_to_read",
    buttonText: "Click here",
    isSupplementTile: false,
  },
  {
    title: "Health\n Report",
    text: "Review over 75+ reports on your genetic predispositions to nutrient deficiencies, vitamin & mineral requirements, training-stimulus response, and injury susceptibilities",
    link: "dashboard",
    buttonText: "Your Result",
    isSupplementTile: false,
  },
  {
    title: "Personalised\n Supplement Plan",
    text: "Review and order a supply of your very own supplement formula that has been entirely customised to your physiological requirements",
    link: "dashboard",
    buttonText: "Your Plan",
    isSupplementTile: true,
  },
];

export const smallCardsContent = [
  {
    text: "Blood Test\n Results",
    type: "sirenge",
  },
  {
    text: "Ai Personalised\n Training Hub",
    type: "gear",
  },
  {
    text: "Performance\n Tracking",
    type: "perfomance",
  },
  {
    text: "Meal\n Plans",
    type: "meal",
  },
];
