import { FC, ReactNode } from "react";
import HealthHeart from "../../assets/svgComponents/HealthHeart";
import Note from "../../assets/svgComponents/Note";
import Bottle from "../../assets/svgComponents/Bottle";
import Tube from "../../assets/svgComponents/Tube";

import classNames from "classnames";

const icons: Record<string, (color: string) => JSX.Element> = {
  questionnaire: (color) => <HealthHeart color={color} />,
  dashboard: (color) => <Note color={color} />,
  supplement: (color) => <Bottle color={color} />,
  how_to_read: (color) => <Bottle color={color} />,
};

interface Props {
  page: string;
  text: string;
  title: string;
  children: ReactNode;
  isUnlocked: boolean;
  isFirstOne: boolean;
  isSupplementTile: boolean;
}

const BigCard: FC<Props> = ({
  children,
  page,
  text,
  title,
  isUnlocked,
  isFirstOne,
  isSupplementTile,
}) => {
  const iconToDisplay = isSupplementTile
    ? (color: string) => <Tube color={color} />
    : icons[page];
  return (
    <div className={classNames("home-big-card-wrap", isFirstOne && "active")}>
      <div>
        <span className="big-card-title">{title}</span>
        <p>{text}</p>
      </div>
      <div className="big-card-footer">
        {children}
        {iconToDisplay(isFirstOne ? "#fff" : "")}
      </div>
    </div>
  );
};

export default BigCard;
