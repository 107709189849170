const Bottle = ({ color }: { color?: string }) => (
  <svg
    width="90"
    height="90"
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M61.249 37.485V27.548C61.249 22.528 57.369 18.404 52.451 17.999V12.674C52.451 11.372 51.392 10.313 50.091 10.313H48.687V6.00098C48.687 5.44898 48.24 5.00098 47.687 5.00098H42.312C41.759 5.00098 41.312 5.44898 41.312 6.00098V10.313H39.908C38.606 10.313 37.548 11.372 37.548 12.674V17.999C32.63 18.403 28.75 22.528 28.75 27.548V37.485C28.75 39.339 29.28 41.137 30.284 42.687C31.014 43.815 31.014 45.332 30.285 46.46C29.28 48.01 28.75 49.809 28.75 51.662V80.728C28.75 83.084 30.667 85 33.022 85H56.977C59.332 85 61.249 83.083 61.249 80.728V51.663C61.249 49.809 60.719 48.011 59.715 46.461C58.985 45.333 58.985 43.816 59.714 42.688C60.719 41.137 61.249 39.338 61.249 37.485ZM43.311 7.00098H46.686V10.313H43.311V7.00098ZM39.907 12.313H50.091C50.289 12.313 50.451 12.475 50.451 12.674V17.959H39.547V12.674C39.547 12.476 39.709 12.313 39.907 12.313ZM59.249 37.485C59.249 38.952 58.829 40.375 58.035 41.6C56.866 43.407 56.866 45.741 58.036 47.547C58.829 48.773 59.249 50.195 59.249 51.662V80.728C59.249 81.981 58.229 83 56.977 83H33.021C31.768 83 30.749 81.98 30.749 80.728V51.663C30.749 50.196 31.169 48.773 31.963 47.548C33.132 45.741 33.132 43.407 31.962 41.601C31.169 40.375 30.749 38.953 30.749 37.486V27.549C30.749 23.364 34.153 19.96 38.338 19.96H51.66C55.845 19.96 59.249 23.364 59.249 27.549V37.485Z"
      fill="url(#paint0_linear_725_7076)"
    />
    <path
      d="M44.141 36.7605C43.368 38.0675 36.582 49.6545 36.582 53.9025C36.582 58.2055 39.829 61.7595 44.001 62.2565V69.9365C44.001 70.4885 44.448 70.9365 45.001 70.9365C45.554 70.9365 46.001 70.4885 46.001 69.9365V62.2565C50.173 61.7595 53.42 58.2055 53.42 53.9025C53.42 49.6545 46.635 38.0685 45.861 36.7605C45.502 36.1515 44.5 36.1515 44.141 36.7605ZM38.582 53.9015C38.582 51.5055 41.65 45.3555 44.001 41.0605V60.2345C40.936 59.7525 38.582 57.1005 38.582 53.9015ZM46.001 60.2345V41.0615C48.352 45.3565 51.42 51.5065 51.42 53.9025C51.42 57.1005 49.066 59.7525 46.001 60.2345Z"
      fill="url(#paint1_linear_725_7076)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_725_7076"
        x1="29.9368"
        y1="33.7186"
        x2="61.287"
        y2="33.8162"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color={color ? color : "#9940F6"} />
        <stop offset="1" stop-color={color ? color : "#6D57FC"} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_725_7076"
        x1="37.1969"
        y1="48.736"
        x2="53.4397"
        y2="48.7965"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color={color ? color : "#9940F6"} />
        <stop offset="1" stop-color={color ? color : "#6D57FC"} />
      </linearGradient>
    </defs>
  </svg>
);

export default Bottle;
