const Tube = ({ color }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    viewBox="0 0 24 24"
    fill="none"
    stroke={color || "#9940F6"} // Set default purple color
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ marginBottom: "10px" }}
  >
    {/* Updated SVG paths */}
    <path d="M10 2v7.31" />
    <path d="M14 9.3V1.99" />
    <path d="M8.5 2h7" />
    <path d="M14 9.3a6.5 6.5 0 1 1-4 0" />
    <path d="M5.52 16h12.96" />
  </svg>
);

export default Tube;
